import React, { forwardRef } from "react"

const Overlay = forwardRef(({ caption, scroll }, ref) => (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
      caption.current.innerText = scroll.current.toFixed(2)
    }}
    class="scroll">
    <div style={{ height: "400vh" }}>
      <div class="dot">
        <h1>RVA NFTs</h1>
        RVA NFTs is a media, software & design studio, created to enhance the Web3 experience in Richmond Va and beyond. The core purpose is to integrate NFTs into new or current business models to facilitate growth and create safe opportunities for collectors & businesses.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Immersive Experiences</h1>
        Elevate your NFT project with totally immersive virtual experiences via the browser or any VR device...don't forget your headphones.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Launch Plan</h1>Build a rocketship for your NFT project and create a flight plan. We'll give you the fuel you need to reach your goals
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Organic Power</h1>Cultivate organic growth and empower your community and ecosytem.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>The Table</h1>The right team at the table is the foundation. Our team will bring the table
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Web Development</h1>Having a solid online presence is important to give your community access to information and drops with little to no friction.  
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Smooth Sailing </h1> NFTs can be used to leverage your core values to lift up your community and fly together. With this team, is no limit to how high you can sore. 
      </div>
    </div>
    <span class="caption" ref={caption}>
      0.00
    </span>
  </div>
))

export default Overlay
